:root:root {
  --rv-button-primary-background-color: #1890ff;
  --rv-button-primary-border-color: #1890ff;
  --rv-nav-bar-icon-color: #1890ff;
}
* {
  margin: 0;
  padding: 0;
}
div,
p,
dl,
dd,
a,
b,
u,
em,
input,
textarea,
button,
select,
form,
section,
label,
span,
td,
li,
nav,
footer,
body,
ul,
i {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  color: #262626;
}
#root {
  width: 100%;
  height: 100%;
}
.container {
  margin: 0 auto;
  width: 100vw;
  min-height: 100vh;
  background: #f7f8fa;
}
.container .mt15 {
  margin-top: 15px;
}
h3 {
  position: relative;
  text-align: center;
  background: #fff;
  height: 50px;
  line-height: 50px;
}
h3 .arrow {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.loadingBox {
  width: 100vw;
  height: 100vh;
  line-height: 100vh;
  text-align: center;
  background: rgba(24, 144, 255, 0.1);
}
.loadingOverlayBox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  line-height: 100vh;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
}
