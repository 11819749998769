@color: #1890ff;
// 公共样式区域
.container {
  margin: 0 auto;
  width: 100vw;
  min-height: 100vh;
  background: #f7f8fa;

  .mt15 {
    margin-top: 15px;
  }
}

h3 {
  position: relative;
  text-align: center;
  background: #fff;
  height: 50px;
  line-height: 50px;
  .arrow {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.loadingBox {
  width: 100vw;
  height: 100vh;
  line-height: 100vh;
  text-align: center;
  background: rgba(24, 144, 255, 0.1);
}

.loadingOverlayBox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  line-height: 100vh;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
}
