@color: #1890ff;
:root:root {
  --rv-button-primary-background-color: @color;
  --rv-button-primary-border-color:@color;
  --rv-nav-bar-icon-color:@color;
}

* {
  margin: 0;
  padding: 0;
  // &::-webkit-scrollbar {
  //   width: 8px;
  //   height: 6px;
  //   background: transparent;
  //   padding-right: 0px;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background: rgba(0, 0, 0, 0.2);
  //   border-radius: 10px;
  //   border: 2px solid #f8f9fb;
  // }
  // &::-webkit-scrollbar-track {
  //   /*滚动条里面轨道*/
  //   box-shadow: inset 0 0 5px #d8d8d8;
  //   border-radius: 10px;
  //   background: #ededed;
  // }
}

div,
p,
dl,
dd,
a,
b,
u,
em,
input,
textarea,
button,
select,
form,
section,
label,
span,
td,
li,
nav,
footer,
body,
ul,
i {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  // list-style: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  // user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  margin: 0;
  padding: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  //   'Droid Sans', 'Helvetica Neue', sans-serif;
  // 字体重置
  font-family: Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  color: #262626;
}
#root {
  width: 100%;
  height: 100%;
}
